import { React, useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { startScanFrameV3 } from 'services/frameService';
// import defaultFrameImg from '../../assets/img/Emplas/stillage.png';
import SashImageHighlighted from 'components/station/SashImageHighlighted';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { CATEGORIES, warningGroupDescs } from 'helpers/utils';
import '../../assets/scss/user.scss';
import FrameImage from 'components/station/FrameImage';

var dayjs = require('dayjs');

export default function FrameDetailV4(props) {
  let barcode = props.barcode;
  let startStage = props.startStage;
  let userId = props.userId;
  let stationId = props.stationId;

  const [frameInfo, setFrameInfo] = useState({
    jobNo: '',
    mainName: '',
    requiredDate: '',
    winNo: '',
    drainageDesc: '',
    groupDesc: '',
    finishDesc: '',
    finish2Desc: '',
    priorityType: '',
    instructions: '',
    totalWeight: 0
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSash, setIsSash] = useState(false);

  const [image, setImage] = useState();

  useEffect(() => {
    if (barcode > 0 || barcode.toString().toLowerCase().startsWith('tg')) {
      var payload = {
        barcode: barcode,
        stage: startStage,
        userId: userId,
        stationId: stationId
      };
      setIsLoading(true);
      startScanFrameV3(payload)
        .then(res => {
          if (warningGroupDescs.includes(res?.frameDetail?.groupDesc)) {
            props.setIsShowPatioWarning(true);
            props.updateQueue('isShowParioWarning');
          }

          if (res?.isForwardManu && stationId == 17) {
            props.setShowModalGFMA(true);
            props.updateQueue('showModalGFMA');
          } else {
            props.setShowModalGFMA(false);
          }

          if (res?.isNewAndCritical) {
            props.setShowNewCriticalAlert(true);
            props.updateQueue('showNewCriticalAlert');
          } else {
            props.setShowNewCriticalAlert(false);
          }

          if (res?.success && res?.image) {
            setImage(res.image);
          }

          if (res?.success && res?.frameDetail) {
            setFrameInfo({ ...res.frameDetail });
            props.onScanStarted();
            props.onScanCompleted(res?.userTrackingId);
            props.setProductCompleted(CATEGORIES.FRAME);
          }

          setIsSash(res.isSash);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          swalToastError(
            'Frame is not found in the system. Please report it to your supervisor'
          );
          setIsLoading(false);
          props.onScanCompleted(0);
          props.setIsScanning(false);
          props.setScannedBarcode(0);
        });
    } else {
      setFrameInfo({
        jobNo: '',
        mainName: '',
        requiredDate: '',
        winNo: '',
        drainageDesc: '',
        groupDesc: '',
        finishDesc: '',
        finish2Desc: '',
        priorityType: '',
        instructions: '',
        totalWeight: 0
      });
    }
  }, [props.barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div style={{ padding: 0, margin: 0 }}>
      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Job No:</div>
        <div className="frame-detail-body__text-value">{frameInfo.jobNo}</div>
      </div>

      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Customer:</div>
        <div className="frame-detail-body__text-value">
          {frameInfo.mainName}
          <Badge bg="danger">{frameInfo.priorityType}</Badge>
        </div>
      </div>

      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Date Required:</div>
        <div className="frame-detail-body__text-value">
          {frameInfo.requiredDate
            ? dayjs(frameInfo.requiredDate).format('DD/MM/YYYY')
            : ''}
        </div>
      </div>
      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Win No:</div>
        <div className="frame-detail-body__text-value">{frameInfo.winNo}</div>
      </div>
      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Drainage:</div>
        <div className="frame-detail-body__text-value">
          {frameInfo.drainageDesc}
        </div>
      </div>
      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Group:</div>
        <div className="frame-detail-body__text-value">
          {frameInfo.groupDesc}
        </div>
      </div>
      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Finish 1:</div>
        <div className="frame-detail-body__text-value">
          {frameInfo.finishDesc}
        </div>
      </div>
      <div className="frame-detail-body">
        <div className="frame-detail-body__text-property">Finish 2:</div>
        <div className="rame-detail-body__text-value">
          {frameInfo.finish2Desc}
        </div>
      </div>
      <div className="frame-detail-body" style={{ color: '#d9534f' }}>
        <div className="frame-detail-body__text-property">
          Special Instructions:
        </div>
        <div className="frame-detail-body__text-value">
          {frameInfo.instructions}
        </div>
      </div>
      <Row className="thinnerRow">
        {isSash ? (
          <Col style={{ textAlign: 'center' }}>
            <SashImageHighlighted content={image} />
          </Col>
        ) : (
          <Col style={{ textAlign: 'center' }}>
            <FrameImage jobNo={frameInfo.jobNo} winNo={frameInfo.winNo} />
          </Col>
        )}
      </Row>
    </div>
  );
}
