import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { getSashAllocator } from 'services/frameService';

export default function SashAllocator({ barcode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [sashAllocator, setSashAllocator] = useState();

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getSashAllocator(barcode)
        .then(res => {
          setIsLoading(false);
          if (res.data) {
            setSashAllocator(res.data);
          }
        })
        .catch(error => {
          swalToastError('Assigned the Rack And Slot Number Failed!');
        });
    }
  }, [barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : barcode ? (
    sashAllocator ? (
      <div style={{ fontSize: 32, fontWeight: 600 }}>
        <div>
          <p>Store In</p>
        </div>
        <div style={{ color: 'red' }}>
          <span>Rack: </span>
          <span>{sashAllocator.rackNumber}</span>
        </div>
        <div style={{ color: 'red' }}>
          <span>Slot: </span>
          <span>{sashAllocator.slotNumber}</span>
        </div>
      </div>
    ) : (
      <h2 style={{ color: 'red' }}>No Open Slots Available</h2>
    )
  ) : (
    <h2> Please Scan Barcode</h2>
  );
}
