import axios from 'axios';

const getStations = async (search = '') => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation?search=${search}`
  );

  try {
    return {
      success: true,
      stations: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getStationByPath = async path => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/station?path=${path}`
  );

  try {
    return {
      success: true,
      station: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getStages = async () => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/stages`
  );

  try {
    return {
      success: true,
      stages: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPanels = async () => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/panels`
  );

  try {
    return {
      success: true,
      panels: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPanelById = async panelId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/panel?panelId=${panelId}`
  );

  try {
    return {
      success: true,
      panel: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateMainSetting = async station => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/main-setting`,
    method: 'PATCH',
    data: {
      id: station.id,
      stationName: station.stationName,
      startStage: station.startStage,
      completedStage: station.completedStage
    }
  });

  try {
    return {
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateWidgetSetup = async setup => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/widget-setup`,
    method: 'PATCH',
    data: setup
  });

  try {
    return {
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addWidgetSetup = async setup => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/widget-setup`,
    method: 'POST',
    data: setup
  });

  try {
    return {
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addStation = async station => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/station`,
    method: 'POST',
    data: station
  });

  try {
    return {
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateStation = async station => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/station`,
    method: 'PATCH',
    data: station
  });

  try {
    return {
      success: res.data.success,
      newPath: res.data.newPath
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getStationLayout = async stationId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/layout?stationId=${stationId}`
  );

  try {
    return {
      success: true,
      layout: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getScanningActions = async () => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/scanning-actions`
  );

  try {
    return {
      success: true,
      actions: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateStationLayout = async (stationId, layout, mode) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/layout?stationId=${stationId}&mode=${mode}`,
    method: 'PATCH',
    data: layout
  });

  try {
    return {
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updatePanelLockMode = async (panelId, mode) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/panel-lock?panelId=${panelId}&mode=${mode}`,
    method: 'PATCH'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updatePanelHeader = async (panelSetupId, newData) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/panel-header?panelSetupId=${panelSetupId}`,
    method: 'PATCH',
    data: newData
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updatePanelSettings = async (
  panelSetupId,
  title,
  filterGroupName,
  colour,
  isShowSize,
  enabledMoveStage
) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/panel-settings?panelSetupId=${panelSetupId}&title=${title}&filterGroup=${filterGroupName}&colour=${colour}&isShowSize=${isShowSize}&enabledMoveStage=${enabledMoveStage}`,
    method: 'PATCH'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateScanningAction = async (actionId, panelId) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/scanning-action?actionId=${actionId}&panelId=${panelId}`,
    method: 'PATCH'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const setUserDarkMode = async (userId, isDark) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/dark-mode?userId=${userId}&isDark=${isDark}`,
    method: 'PATCH'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getUserDarkMode = async userId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/dark-mode?userId=${userId}`
  );

  try {
    return {
      isDark: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateStations = stations => {
  return axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation`,
    method: 'PUT',
    data: stations
  });
};

const getPCName = async () => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/pc-name`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getStationPCSettings = async search => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/station-pc-seting?search=${search}`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addStationPCSettings = async data => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/station-pc-seting`,
    method: 'POST',
    data: data
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const editStationPCSettings = async (id, data) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/station-pc-seting?id=${id}`,
    method: 'PUT',
    data: data
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteStationPCSettings = async id => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/station-pc-seting/${id}`,
    method: 'DELETE'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getStations,
  getStationByPath,
  getStages,
  getPanels,
  getPanelById,
  getScanningActions,
  getUserDarkMode,
  updateMainSetting,
  updateWidgetSetup,
  addWidgetSetup,
  addStation,
  updateStation,
  getStationLayout,
  updateStationLayout,
  updatePanelLockMode,
  updatePanelHeader,
  updatePanelSettings,
  updateScanningAction,
  setUserDarkMode,
  updateStations,
  getPCName,
  getStationPCSettings,
  addStationPCSettings,
  editStationPCSettings,
  deleteStationPCSettings
};
