import StationSpinner from 'components/station/common/Spinner';
import { FrameSashLabel } from 'components/station/FrameSashLabel';
import { swalToastError } from 'helpers/component-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { getlabelsToPrint } from 'services/frameService';
import './../../assets/scss/framesashlabel.scss';
export const PrintLabels = ({
  isShowPrintLabels,
  setIsShowPrintLabels,
  stationId
}) => {
  const componentRef = useRef(null);

  const [numnberOfItems, setNumberOfItems] = useState(1);
  const [jobNo, setJobNo] = useState('');
  const [winNo, setWinNo] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPrinting, setItemsPrinting] = useState([]);

  const onPrintLabels = useReactToPrint({
    content: () => componentRef.current
  });

  const onChangeJobNo = e => {
    setJobNo(e.target.value);
  };

  const onChangeNumnerOfItems = e => {
    setNumberOfItems(e.target.value);
  };

  const onChangeWinNo = e => {
    setWinNo(e.target.value);
  };

  const onChecked = (e, item) => {
    if (e.target.checked) {
      setItemsPrinting([...itemsPrinting, item]);
    } else {
      setItemsPrinting(
        itemsPrinting.filter(
          i =>
            i.winNo != item.winNo ||
            i.sashNo != item.sashNo ||
            i.frameId != item.frameId
        )
      );
    }
  };

  useEffect(() => {
    console.log(itemsPrinting);
  }, [itemsPrinting]);

  useEffect(() => {
    if (stationId) {
      clearTimeout(delay);
      var delay = setTimeout(() => {
        setItemsPrinting([]); // remove all preview when change jobn or winno
        setIsLoading(true);
        getlabelsToPrint(jobNo, winNo, stationId)
          .then(res => {
            if (res && res.data) {
              setData(res.data);
            }
            setIsLoading(false);
          })
          .catch(err => {
            swalToastError('Get Labels To Print Failed!');
            setIsLoading(false);
          });
      }, 1000);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [jobNo, winNo, stationId]);

  const generateItem = item => {
    let result = 'Win No: ' + item.winNo;
    if (item.sashNo > 0) {
      result += ` - Sash ${item.sashNo}`;
    } else {
      result += ` - ${item.frameWidth} x ${item.frameHeight} ${item.groupDesc}`;
    }

    return result;
  };

  const generateTotalItemsPrint = () => {
    const totalItems = [];
    for (let i = 0; i < numnberOfItems; i++) {
      totalItems.push(<FrameSashLabel key={i} itemsPrinting={itemsPrinting} />);
    }

    return totalItems;
  };

  return (
    <Modal
      show={isShowPrintLabels}
      animation={false}
      autoFocus={false}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>
          <h3>Print Frame/Sash Labels</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Form.Group className="mb-3" controlId="numberOfItems">
            <Form.Label>Number Of Items</Form.Label>
            <Form.Control
              type="Number"
              min={1}
              name="numberOfItems"
              value={numnberOfItems}
              onChange={onChangeNumnerOfItems}
            ></Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="jobno">
            <Form.Label>Job No: </Form.Label>
            <Form.Control
              name="jobNo"
              value={jobNo}
              onChange={onChangeJobNo}
            ></Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="winNo">
            <Form.Label>Win No: </Form.Label>
            <Form.Control
              type="number"
              min={1}
              name="winNo"
              value={winNo}
              onChange={onChangeWinNo}
            ></Form.Control>
          </Form.Group>
        </div>

        <Card>
          <Card.Header>
            <h5>Frames/Sashes: </h5>
          </Card.Header>
          <Card.Body style={{ display: 'flex' }}>
            {isLoading ? (
              <StationSpinner />
            ) : (
              <div
                style={{ maxHeight: '400px', overflow: 'auto', width: '600px' }}
              >
                {data.length > 0 &&
                  data.map((item, id) => (
                    <Form.Check
                      type="checkbox"
                      id={id + 1}
                      style={{
                        fontSize: 18,
                        margin: '0 0 0 5px'
                      }}
                      key={id + 1}
                    >
                      <Form.Check.Input
                        style={{ cursor: 'pointer' }}
                        type={'checkbox'}
                        onChange={e => onChecked(e, item)}
                      />
                      <Form.Check.Label
                        style={{ margin: 0, cursor: 'pointer' }}
                      >
                        {generateItem(item)}
                      </Form.Check.Label>
                    </Form.Check>
                  ))}
              </div>
            )}

            <div
              style={{
                maxHeight: '400px',
                overflowY: 'auto',
                width: '475px',
                overflowX: 'hidden'
              }}
            >
              <div
                ref={componentRef}
                style={{
                  height: '38mm',
                  width: '100mm'
                }}
              >
                {generateTotalItemsPrint()}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Button variant="primary" onClick={onPrintLabels}>
            Print
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setNumberOfItems(1);
              setJobNo('');
              setWinNo('');
              setIsShowPrintLabels(false);
              setItemsPrinting([]);
            }}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
