import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { getSize } from 'services/frameService';

const defaultSize = {
  width: '',
  height: '',
  sashNo: 0,
  drainage: ''
};

export default function SizeWidget({ barcode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(defaultSize);

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getSize(barcode)
        .then(res => {
          setIsLoading(false);
          if (res.data) {
            setSize(res.data);
          } else {
            setSize(defaultSize);
          }
        })
        .catch(error => {
          swalToastError('Get Size Failed!');
        });
    }
  }, [barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : barcode ? (
    size.sashNo > 0 ? (
      <div
        style={{
          fontSize: 32,
          fontWeight: 600,
          color: '#0275d8'
        }}
      >
        <div>
          <span>Sash Number: </span>
          <span>{size.sashNo}</span>
        </div>
        <div>
          <span>Sash Width: </span>
          <span>{size.width}</span>
        </div>
        <div>
          <span>Sash Height: </span>
          <span>{size.height}</span>
        </div>
      </div>
    ) : (
      <div
        style={{
          fontSize: 32,
          fontWeight: 600,
          color: '#0275d8'
        }}
      >
        <div>
          <span>Frame Width: </span>
          <span>{size.width}</span>
        </div>
        <div>
          <span>Frame Height: </span>
          <span>{size.height}</span>
        </div>
        <div
          style={{
            fontSize: 24
          }}
        >
          <span>Drainage: </span>
          <span>{size.drainage}</span>
        </div>
      </div>
    )
  ) : (
    <h2> Please Scan Barcode</h2>
  );
}
