import axios from 'axios';

const getFrameImageSizeSetting = async panelSetupId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/frame-image-size?panelSetupId=${panelSetupId}`
  );
  try {
    return {
      success: true,
      size: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getShowSizePanelSetup = async panelSetupId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/show-size?panelSetupId=${panelSetupId}`
  );
  try {
    return {
      success: true,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getEnabledMoveStageState = async panelSetupId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/EvaScanStation/enable-move-stage?panelSetupId=${panelSetupId}`
  );
  try {
    return {
      success: true,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateFrameImageSizeSetting = async (
  panelSetupId,
  title,
  colour,
  filterGroup,
  payload
) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/frame-image-size?panelSetupId=${panelSetupId}&title=${title}&colour=${colour}&filterGroup=${filterGroup}`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getFrameImageSizeSetting,
  updateFrameImageSizeSetting,
  getShowSizePanelSetup,
  getEnabledMoveStageState
};
