import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getFrameImageSize } from 'redux/slices/widgetSlice';

export default function FrameImageV2(props) {
  let jobNo = props.jobNo;
  let winNo = props.winNo;
  let panelSetupId = props.panelSetupId;
  let imgPath = `${process.env.REACT_APP_API_HOST}/FrameImage/${jobNo}/${winNo}`;

  const frameImgSize = useSelector(store => store.widget.frameImageSize);
  const dispatch = useDispatch();

  useEffect(() => {
    if (panelSetupId) dispatch(getFrameImageSize(panelSetupId));
  }, [jobNo, winNo]);

  return jobNo ? (
    <Image
      width={300}
      style={{ objectFit: 'contain', maxHeight: '300px', maxWidth: '300px' }}
      src={imgPath}
      className="center-block"
      alt="frame image"
      unoptimized="true"
    />
  ) : (
    <h4>No image found</h4>
  );

  // return frameImgSize && frameImgSize.width > 0 ? (
  //   <Image
  //     src={'https://localhost:7284/FrameImage/652369/6'}
  //     className="center-block"
  //     width={frameImgSize.width}
  //     height={'auto'}
  //     alt="frame image"
  //     unoptimized="true"
  //   ></Image>
  // ) : (
  //   <Image
  //     src={'https://localhost:7284/FrameImage/652369/6'}
  //     className="center-block"
  //     alt="frame image"
  //     unoptimized="true"
  //   ></Image>
  // );
}
