import FrameImageV2 from 'components/station/FrameImageV2';
import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToast, swalToastError } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import { startScanFrameV2 } from 'services/frameService';
import '../../assets/scss/user.scss';
import { CATEGORIES, warningGroupDescs } from 'helpers/utils';

export default function FrameDetailV2Widget(props) {
  let barcode = props.barcode;
  let startStage = props.startStage;
  let userId = props.userId;
  let stationId = props.stationId;

  const panelSetupId = props.panelSetupId;
  const [frameInfo, setFrameInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [priorityType, setPriorityType] = useState('');

  useEffect(() => {
    if (barcode > 0 || barcode.toString().toLowerCase().startsWith('tg')) {
      setIsLoading(true);
      var payload = {
        barcode: barcode,
        stage: startStage,
        userId: userId,
        stationId: stationId
      };

      startScanFrameV2(payload)
        .then(res => {
          if (warningGroupDescs.includes(res?.frameDetail?.groupDesc)) {
            props.setIsShowPatioWarning(true);
            props.updateQueue('isShowParioWarning');
          }

          if (res?.isForwardManu && stationId == 17) {
            props.setShowModalGFMA(true);
            props.updateQueue('showModalGFMA');
          } else {
            props.setShowModalGFMA(false);
          }

          if (res?.isNewAndCritical) {
            props.setShowNewCriticalAlert(true);
            props.updateQueue('showNewCriticalAlert');
          } else {
            props.setShowNewCriticalAlert(false);
          }

          if (res?.success && res?.frameDetail) {
            setPriorityType(res.newAndCriticalType);
            setFrameInfo(res.frameDetail);
            props.onScanStarted();
            props.onScanCompleted(res?.userTrackingId);
            props.setProductCompleted(CATEGORIES.FRAME);
          }
          setIsLoading(false);
        })
        .catch(err => {
          swalToastError(
            'Frame is not found in the system. Please report it to your supervisor'
          );

          setIsLoading(false);
          props.onScanCompleted(0);
          props.setIsScanning(false);
          props.setScannedBarcode(0);
        });
    } else {
      setFrameInfo({});
    }
  }, [props.barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div style={{ padding: 0, margin: 0 }}>
      <Table
        bordered
        style={{ border: '1px solid black', fontSize: '14px' }}
        className="table-sm frame-detail-v2-table-1"
      >
        <tbody>
          <tr>
            <td style={{ width: '20%' }}></td>
            <td style={{ width: '20%' }}>
              <b>
                {frameInfo.requiredDate
                  ? dayjs(frameInfo.requiredDate).format('DD/MM/YYYY')
                  : ''}
              </b>
            </td>
            <td style={{ width: '40%' }} colSpan={2}>
              <b>{frameInfo.mainName}</b>{' '}
              <Badge bg="danger">{priorityType}</Badge>
            </td>
            <td
              className="px-0"
              style={{
                width: '20%',
                verticalAlign: 'middle',
                textAlign: 'center'
              }}
              rowSpan={2}
            >
              <b className="job-no-title">
                <b>{frameInfo.jobNo}</b>
              </b>
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>
              <b>ITEM: {frameInfo.winNo}</b>
            </td>
            <td style={{ width: '20%' }}>
              <b>{frameInfo.locationA}</b>
            </td>
            <td style={{ width: '20%' }}>
              <b>{frameInfo.loadCode}</b>
            </td>
            <td style={{ width: '20%' }}>
              <b>{frameInfo.reference}</b>
            </td>
          </tr>
        </tbody>
      </Table>

      <Table
        bordered
        style={{
          border: '1px solid black',
          marginBottom: '1px',
          fontSize: '14px'
        }}
        className="table-sm frame-detail-v2-table-2"
      >
        <tbody>
          <tr>
            <td className="fs-10" style={{ width: '20%' }}>
              Group
            </td>
            <td className="fs-10" style={{ width: '80%' }}>
              {frameInfo.groupDesc} {frameInfo.finishDesc}
            </td>
          </tr>
          <tr>
            <td className="fs-10" style={{ width: '20%' }}>
              <b>Basic Frame Size</b>
            </td>
            <td className="fs-10" style={{ width: '80%' }}>
              <b>
                {frameInfo.frameWidth} x {frameInfo.frameHeight}
              </b>
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>
              <b>Overall Size</b>
            </td>
            <td style={{ width: '80%' }}>
              <b>
                {frameInfo.windowWidth} x {frameInfo.windowHeight}
              </b>
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Frame Head</td>
            <td style={{ width: '80%' }}>
              {frameInfo.topOuter} {frameInfo.finishDesc}
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Frame Cill</td>
            <td style={{ width: '80%' }}>
              {frameInfo.bottomOuter} {frameInfo.finishDesc}
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Frame Left Jamb</td>
            <td style={{ width: '80%' }}>
              {frameInfo.leftOuter} {frameInfo.finishDesc}
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Frame Right Jamb</td>
            <td style={{ width: '80%' }}>
              {frameInfo.rightOuter} {frameInfo.finishDesc}
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Cill</td>
            <td style={{ width: '80%' }}>
              {frameInfo.cillDesc} {frameInfo.finishDesc}
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Drainage</td>
            <td style={{ width: '80%' }}>{frameInfo.drainageDesc}</td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Reinforcing Type</td>
            <td style={{ width: '80%' }}>{frameInfo.reinforcementDesc}</td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Bead Type</td>
            <td style={{ width: '80%' }}>{frameInfo.beadDesc}</td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>
              <b>Handle</b>
            </td>
            <td style={{ width: '80%' }}>
              <b>{frameInfo.handle}</b>
            </td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Locking</td>
            <td style={{ width: '80%' }}>{frameInfo.lock}</td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Hinge</td>
            <td style={{ width: '80%' }}>{frameInfo.hinge}</td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Cylinder</td>
            <td style={{ width: '80%' }}>{frameInfo.cylinder}</td>
          </tr>
          <tr>
            <td style={{ width: '20%' }}>Glass</td>
            <td style={{ width: '80%' }}>{frameInfo.glassDesc}</td>
          </tr>
        </tbody>
      </Table>

      <div
        className="d-flex mt-0"
        style={{ paddingLeft: '20px', paddingRight: '20px' }}
      >
        <b className="gap-2" style={{ color: '#d9534f' }}>
          Special Instructions:
        </b>
        &nbsp;
        <b style={{ color: '#d9534f' }}>{frameInfo.instructions}</b>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FrameImageV2
          jobNo={frameInfo.jobNo}
          winNo={frameInfo.winNo}
          stationId={stationId}
          panelSetupId={panelSetupId}
        />
      </div>
    </div>
  );
}
