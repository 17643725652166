import { faCog, faThumbtack, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colourData } from 'data/stations/colourData';
import {
  widgetWithFilterGroupSetup,
  widgetWithFrameImage,
  widgetWithPartImage
} from 'data/stations/widgetData';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getPermission } from 'redux/slices/permissionSlice';
import { getStationFilterGroups } from 'redux/slices/stationSlice';
import {
  getEnabledMoveStage,
  getFrameImageSize,
  getPartImageSize,
  getPartV5ImageSize,
  getPartV8ShowSize
} from 'redux/slices/widgetSlice';
import {
  getPanelById,
  updatePanelHeader,
  updatePanelSettings
} from 'services/stationService';
import { updateFrameImageSizeSetting } from 'services/widgetSettingService';
import '../../assets/scss/user.scss';

const Panel = React.forwardRef(
  (
    {
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children,
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const title = props.title;
    const titleColour = props.titleColour;
    const fontSize = props.fontSize;
    const panelBody = props.body;
    const isPinned = props.isPinned;
    const panelSetupId = props.panelSetupId;
    const panelId = props.panelId;
    const dbFilterGroup = props.filterGroup;
    const showFrameWeight = props.showFrameWeight;
    const station = props.station;

    const [modalSetupShow, setModalSetupShow] = useState(false);
    const [isLocked, setIsLocked] = useState(isPinned);
    const [panelTitle, setPanelTitle] = useState(title);
    const [panelFontSize, setPanelFontSize] = useState(fontSize);
    const [filterGroup, setFilterGroup] = useState(
      dbFilterGroup ? { label: dbFilterGroup, value: dbFilterGroup } : ''
    );
    const [panelTitleColour, setPanelTitleColour] = useState(
      titleColour ?? '#2C7BE5'
    ); // default colour
    const [panelMaster, setPanelMaster] = useState({});
    const [validated, setValidated] = useState(false);
    const [frameWeightDisplayed, setFrameWeightDisplayed] =
      useState(showFrameWeight);

    let stageOptions = [];
    let filterGroupOtions = [];
    let colourOtions = [];

    const stages = useSelector(store => store.station.stages);
    const filterGroups = useSelector(store => store.station.filterGroups);
    let permissionId = useSelector(store => store.permission.permissionId);
    const permissions = useSelector(store => store.permission.permissions);
    const isShowSize = useSelector(store => store.widget.partV8ShowSize);
    const enabledMoveStage = useSelector(
      store => store.widget.enabledMoveStage
    );

    const [frameImageSize, setFrameImageSize] = useState({});
    const [partImageSize, setPartImageSize] = useState({});
    const [showOriginalSize, setShowOriginalSize] = useState(false);
    const [filterGroupState, setFilterGroupState] = useState([]);
    const [showSizeState, setShowSizeState] = useState(false);
    const [enabledMoveStageState, setEnabledMoveStageState] = useState(false);

    useEffect(() => {
      if (permissionId <= 0) {
        let loggedData = JSON.parse(localStorage.getItem('login-data'));
        if (!loggedData) permissionId = 0;
        else dispatch(getPermission(loggedData.permissionId));
      }
    }, []);

    useEffect(() => {
      dispatch(getStationFilterGroups());

      if (props.panelId) {
        getPanelById(panelId).then(res => {
          setPanelMaster({ ...panelMaster, ...res.panel });
        });

        if (widgetWithFrameImage.includes(panelId)) {
          dispatch(getFrameImageSize(panelSetupId)).then(val => {
            setFrameImageSize(val.payload);
            if (val.payload.width == 0) {
              setShowOriginalSize(true);
            }
          });
        }

        if (widgetWithPartImage.includes(panelId)) {
          dispatch(getPartImageSize(panelSetupId)).then(val => {
            setPartImageSize(val.payload);
          });
        }
      }
    }, [props.panelId]);

    useEffect(() => {
      setShowSizeState(isShowSize);
    }, [isShowSize]);

    useEffect(() => {
      setEnabledMoveStageState(enabledMoveStage || false);
    }, [enabledMoveStage]);

    useEffect(() => {
      if (filterGroups.length > 0) {
        setFilterGroupState(
          filterGroups.map(item => {
            return {
              value: item,
              label: item
            };
          })
        );
      }
    }, [filterGroups]);

    const handleRemovePanel = e => {
      e.stopPropagation();

      props.onRemovePanel(panelSetupId);
    };

    const handleLock = e => {
      e.stopPropagation();

      setIsLocked(!isLocked);
      let payload = { id: panelSetupId, nextMode: !isLocked };
      props.onLockPanel(payload);
    };

    const handlePanelSetting = e => {
      e.stopPropagation();

      setModalSetupShow(true);
    };

    const handleFilterGroupChange = value => {
      setFilterGroup(value);
    };

    const handleTitleColourChange = e => {
      setPanelTitleColour(e.target.value);
    };

    const handleSubmitSetup = e => {
      const form = e.currentTarget;

      e.preventDefault();
      e.stopPropagation();

      if (form.checkValidity() === false) {
        setValidated(true);
      }

      let payload = {
        title: panelTitle,
        colour: panelTitleColour.substring(1),
        fontSize: panelFontSize,
        frameWeightDisplayed: frameWeightDisplayed
      };

      if (widgetWithFilterGroupSetup.includes(panelId)) {
        if (widgetWithPartImage.includes(panelId)) {
          let sizeObject = partImageSize;

          updateFrameImageSizeSetting(
            panelSetupId,
            panelTitle,
            panelTitleColour.substring(1),
            filterGroup.value,
            sizeObject
          ).then(res => {
            if (res?.success) {
              switch (panelId) {
                case 17:
                  dispatch(getPartImageSize(panelSetupId));
                  break;
                case 18:
                  dispatch(getPartV5ImageSize(panelSetupId));
                  break;
              }

              swalToastSuccess('Successfully');
              setModalSetupShow(false);
            } else {
              swalToastError('Failed. Please try again');
            }
          });
        } else {
          updatePanelSettings(
            panelSetupId,
            panelTitle,
            filterGroup.value || '',
            panelTitleColour.substring(1),
            showSizeState,
            enabledMoveStageState
          ).then(res => {
            if (res.success) {
              dispatch(getPartV8ShowSize(panelSetupId));
              dispatch(getEnabledMoveStage(panelSetupId));
              swalToastSuccess('Successfully');
              setModalSetupShow(false);
            } else {
              swalToastError('Failed. Please try again');
            }
          });
        }
      } else if (widgetWithFrameImage.includes(panelId)) {
        let sizeObject = showOriginalSize
          ? { width: 0, height: 0 }
          : frameImageSize;

        updateFrameImageSizeSetting(
          panelSetupId,
          panelTitle,
          panelTitleColour.substring(1),
          filterGroup.value,
          sizeObject
        ).then(res => {
          if (res?.success) {
            dispatch(getFrameImageSize(panelSetupId));
            swalToastSuccess('Successfully');
            setModalSetupShow(false);
          } else {
            swalToastError('Failed. Please try again');
          }
        });
      } else {
        updatePanelHeader(panelSetupId, payload).then(res => {
          if (res.success) {
            swalToastSuccess('Successfully');
            setModalSetupShow(false);
          } else {
            swalToastError('Failed. Please try again');
          }
        });
      }
    };

    const onShowOriginalChange = isChecked => {
      setShowOriginalSize(isChecked);
    };

    if (colourData.length > 0) {
      colourData.forEach((item, index) => {
        colourOtions.push(
          <option
            key={`cl${index}`}
            value={item.value}
            style={{ backgroundColor: item.value }}
          >
            {item.name}
          </option>
        );
      });
    } else {
      colourOtions.push(
        <option key={`cl0`} value={''}>
          No colour found
        </option>
      );
    }

    if (stages.length > 0) {
      stages.forEach((item, index) => {
        stageOptions.push(
          <option key={`stage${index}`} value={item.id}>
            {item.name}
          </option>
        );
      });
    } else {
      stageOptions.push(
        <option key={`stage0`} value={0}>
          No stages found
        </option>
      );
    }

    let cursor = 'move';
    if (isLocked) {
      cursor = 'no-drop';
    }

    let panelCss =
      panelId == 3 // apply for frame detail panel only
        ? { overflow: 'auto', fontSize: `${panelFontSize}px` }
        : { overflow: 'auto' };

    return (
      <>
        <Card
          style={{ ...style, cursor: cursor }}
          className={className}
          ref={ref}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
        >
          <Card.Header
            className="d-flex align-items-center justify-content-between flex-wrap"
            style={{
              backgroundColor: panelTitleColour,
              padding: '0.2rem 0.5rem',
              minWidth: 'max-content'
            }}
          >
            <div
              className="pr-2"
              style={{ color: 'white', fontSize: '18px', fontWeight: '600' }}
            >
              {panelTitle}
            </div>
            <div className="d-flex align-items-center flex-row-reverse gap-2">
              {permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    color: 'white',
                    cursor: 'pointer'
                  }}
                  onClick={e => handleRemovePanel(e)}
                  onTouchStart={e => handleRemovePanel(e)}
                ></FontAwesomeIcon>
              ) : (
                ''
              )}
              {permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
                isLocked ? (
                  <FontAwesomeIcon
                    icon={faThumbtack}
                    style={{
                      color: '#FCF55F',
                      cursor: 'pointer'
                    }}
                    onClick={e => handleLock(e)}
                    onTouchStart={e => handleLock(e)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faThumbtack}
                    style={{
                      color: 'white',
                      cursor: 'pointer'
                    }}
                    onClick={handleLock}
                    onTouchStart={e => handleLock(e)}
                  />
                )
              ) : (
                ''
              )}
              {permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    color: 'white',
                    cursor: 'pointer'
                  }}
                  onClick={e => handlePanelSetting(e)}
                  onTouchStart={e => handlePanelSetting(e)}
                ></FontAwesomeIcon>
              ) : (
                ''
              )}
            </div>
          </Card.Header>
          <Card.Body style={panelCss} className="panel-body">
            <div style={{ padding: '3px' }}>{panelBody}</div>
          </Card.Body>
          {children}{' '}
          {/* Make sure to include children to add resizable handle */}
        </Card>
        <Modal
          show={modalSetupShow}
          dialogClassName="modal-30w"
          size="sm"
          animation={false}
          autoFocus={false}
        >
          <Modal.Header>
            <Modal.Title>Panel Setup</Modal.Title>
            <FontAwesomeIcon
              icon={faTimes}
              cursor={'pointer'}
              onClick={() => {
                setModalSetupShow(false);
              }}
            ></FontAwesomeIcon>
          </Modal.Header>
          <Modal.Body>
            <Form
              autoComplete="off"
              noValidate
              validated={validated}
              onSubmit={handleSubmitSetup}
            >
              <Form.Group
                className="mb-3"
                controlId="setup.txtPanelOriginalName"
              >
                <Form.Label>Panel Original Name</Form.Label>
                <Form.Control
                  type="text"
                  name="txtPanelOriginalName"
                  value={panelMaster.name}
                  onChange={e => {
                    console.log('test');
                  }}
                  readOnly={true}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="setup.txtPanelTitle">
                <Form.Label>Panel Title</Form.Label>
                <Form.Control
                  type="text"
                  name="txtPanelTitle"
                  required
                  autoFocus={true}
                  value={panelTitle}
                  onChange={e => {
                    setPanelTitle(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please input panel title
                </Form.Control.Feedback>
              </Form.Group>
              {panelId == 3 || panelId == 4 ? (
                <Form.Group className="mb-3" controlId="setup.txtPanelFontSize">
                  <Form.Label>Panel Font Size</Form.Label>
                  <Form.Control
                    type="number"
                    name="txtPanelFontSize"
                    required
                    value={panelFontSize}
                    onChange={e => {
                      //console.log(e.target.value);
                      setPanelFontSize(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please input panel font size
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                ''
              )}
              <Form.Group className="mb-3" controlId="setup.panelTitleColour">
                <Form.Label>Panel Title Colour</Form.Label>
                <Form.Control
                  as="select"
                  name="panelTitleColour"
                  value={panelTitleColour}
                  onChange={handleTitleColourChange}
                >
                  {colourOtions}
                </Form.Control>
              </Form.Group>

              {widgetWithFilterGroupSetup.includes(panelId) ? (
                <Form.Group className="mb-3" controlId="setup.filterGroup">
                  <Form.Label>Filter Group</Form.Label>

                  <Select
                    closeMenuOnSelect={false}
                    options={filterGroupState}
                    placeholder="Select Filter Group"
                    classNamePrefix="react-select"
                    value={filterGroup}
                    onChange={handleFilterGroupChange}
                  />
                  {/* <Form.Control
                    as="select"
                    name="filterGroup"
                    value={filterGroup}
                    onChange={handleFilterGroupChange}
                  >
                    {filterGroupOtions}
                  </Form.Control> */}
                </Form.Group>
              ) : (
                ''
              )}
              {panelId === 3 ? (
                <Form.Group className="mb-3" controlId="setup.inputStartStage">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show Frame Weight?"
                    checked={frameWeightDisplayed}
                    onChange={e => {
                      console.log(e.target.checked);
                      setFrameWeightDisplayed(e.target.checked);
                    }}
                  />
                </Form.Group>
              ) : (
                ''
              )}
              {widgetWithFrameImage.includes(panelId) ? (
                <Form.Group className="mb-3" controlId="setup.txtPanelFontSize">
                  <Form.Label>Image Width (pixel)</Form.Label>
                  <Form.Control
                    type="number"
                    name="txtFrameWidth"
                    required
                    value={frameImageSize.width || 0}
                    onChange={e => {
                      setFrameImageSize(prev => ({
                        ...prev,
                        width: e.target.value
                      }));
                    }}
                  />
                </Form.Group>
              ) : (
                ''
              )}
              {widgetWithFrameImage.includes(panelId) ? (
                <Form.Group className="mb-3" controlId="setup.txtPanelFontSize">
                  <Form.Group
                    className="mb-3"
                    controlId="setup.inputStartStage"
                  >
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Show original size?"
                      checked={showOriginalSize}
                      onChange={e => {
                        console.log(e.target.checked);
                        onShowOriginalChange(e.target.checked);
                        // setFrameWeightDisplayed(e.target.checked);
                      }}
                    />
                  </Form.Group>
                </Form.Group>
              ) : (
                ''
              )}
              {widgetWithPartImage.includes(panelId) ? (
                <Form.Group className="mb-3" controlId="setup.txtPartWidth">
                  <Form.Label>Image Width (pixel)</Form.Label>
                  <Form.Control
                    type="number"
                    name="txtPartWidth"
                    required
                    value={partImageSize.width}
                    onChange={e => {
                      setPartImageSize(prev => ({
                        ...prev,
                        width: e.target.value
                      }));
                    }}
                  />
                </Form.Group>
              ) : (
                ''
              )}
              {widgetWithPartImage.includes(panelId) ? (
                <Form.Group className="mb-3" controlId="setup.txtPartHeight">
                  <Form.Label>Image Height (pixel)</Form.Label>
                  <Form.Control
                    type="number"
                    name="txtPartHeight"
                    required
                    value={partImageSize.height}
                    onChange={e => {
                      setPartImageSize(prev => ({
                        ...prev,
                        height: e.target.value
                      }));
                    }}
                  />
                </Form.Group>
              ) : (
                ''
              )}

              {panelId == 37 && (
                <Form.Group className="mb-3" controlId="hideSize">
                  <Form.Group className="mb-3" controlId="hideSize">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Show Size Column"
                      checked={showSizeState}
                      onChange={e => {
                        setShowSizeState(e.target.checked);
                      }}
                    />
                  </Form.Group>
                </Form.Group>
              )}

              {panelId == 33 && (
                <Form.Group className="mb-3" controlId="enableMoveStage">
                  <Form.Group className="mb-3" controlId="enableMoveStage">
                    <Form.Check
                      type="switch"
                      id="enable-move-stage-switch"
                      label="Enable Move Stage"
                      checked={enabledMoveStageState}
                      onChange={e => {
                        setEnabledMoveStageState(e.target.checked);
                      }}
                    />
                  </Form.Group>
                </Form.Group>
              )}

              <Form.Group className="mb-3">
                <Button type="submit" className="btn btn-primary">
                  Save
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
);
export default Panel;
