import HotTable from '@handsontable/react';
import AccessDenied from 'components/errors/AccessDenied';
import 'handsontable/dist/handsontable.full.min.css';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import {
  deleteProfile,
  getAllHingeScrewsMatrixes,
  updateProfile,
  uploadFile
} from 'services/screwsMatrixesService';
import Swal from 'sweetalert2';

const ScrewsMaxtrixes = () => {
  const hotRef = useRef(null);
  const inputRef = useRef(null);
  const [screws, setScrews] = useState([]);
  const [files, setFiles] = useState();
  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    if (permissionId <= 0) {
      verifyPermission();
    }
  }, [permissionId]);

  const verifyPermission = () => {
    let loggedData = JSON.parse(localStorage.getItem('login-data'));
    if (loggedData && loggedData.isLogged) {
      dispatch(getPermission(loggedData.permissionId));
    }
  };

  useEffect(() => {
    getAllHingeScrewsMatrixes()
      .then(res => {
        if (res.data && res.data.length > 0) {
          setScrews(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Screws Maxtrixes Failed!. Please Try Again');
      });
  }, []);

  const handleCellClick = (e, coords, TD) => {
    if (coords.col == 8) {
      const id = hotRef.current.hotInstance.getDataAtCell(coords.row, 0);
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to delete?',
        text: `You won't be able to revert this!`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#d33',
        cancelButtonText: `Cancel`
      })
        .then(result => {
          if (result.isConfirmed) {
            deleteProfile(id).then(res => {
              if (res.data) {
                swalToastSuccess('Delete Screw Matrix Profile Sucessfull!');
                setScrews(screws.filter(s => s.id != id));
              } else {
                swalToastError('Delete Screw Matrix Profile Failed!');
              }
            });
          }
        })
        .catch(error => {
          swalToastError('Delete Screw Matrix Profile Failed!');
        });
    }
  };

  const onChangeCell = changes => {
    if (changes && changes.length > 0) {
      changes.forEach(([row, prop, oldValue, newValue]) => {
        const id = hotRef.current.hotInstance.getDataAtCell(row, 0);
        const item = screws.find(s => s.id == id);
        item[prop] = newValue;
        //TODO Update Item
        updateProfile(item).catch(error => {
          swalToastError('Update Cell Data Failed!');
        });
        setScrews([...screws]);
      });
    }
  };

  const renderButton = (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) => {
    td.style.textAlign = 'center';
    td.style.color = 'red';
    td.innerHTML = `<svg style=" cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>`;
  };

  const onChangeFile = e => {
    if (
      e.target.files.length > 0 &&
      e.target.files[0].name.split('.').pop() != 'csv'
    ) {
      swalToastError('This file type is not supported!');
      e.target.value = '';
      return;
    }
    setFiles(e.target.files);
  };

  const onSubmitFile = () => {
    const formData = new FormData();
    if (!files || files.length <= 0) {
      swalToastError('Please add the file to input!');
      return;
    }
    formData.append('files', files[0]);

    uploadFile(formData)
      .then(res => {
        if (res && res.data) {
          getAllHingeScrewsMatrixes()
            .then(res => {
              if (res.data && res.data.length > 0) {
                setScrews(res.data);
              }
            })
            .catch(error => {
              swalToastError('Get Screws Maxtrixes Failed!. Please try again');
            });
        }
        swalToastSuccess('Uploaded File! Success');
        inputRef.current.value = '';
      })
      .catch(error => swalToastError('Upload File Failed!. Please try again'));
  };

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <>
      <p>Please use the csv file and keep the format to upload</p>
      <div className="d-flex mb-5">
        <input
          multiple={false}
          type="file"
          className="form-control w-75"
          onChange={onChangeFile}
          accept=".csv"
          ref={inputRef}
        />
        <Button type="button" onClick={onSubmitFile}>
          Submit File
        </Button>
      </div>

      <div className="Row">
        <div className="col-lg-12">
          <HotTable
            data={screws}
            rowHeaders={false}
            ref={hotRef}
            className="htCenter"
            fixedColumnsStart={1}
            allowInsertRow={true}
            columnSorting={{
              initialConfig: {
                column: 1,
                sortOrder: 'asc'
              }
            }}
            colHeaders={[
              'ID',
              'PCode',
              'Description',
              'Pack Type',
              'Screws To Sash',
              'Screws To Outer',
              'Screws Put Sashline',
              'Screws Put Hanging',
              'Action'
            ]}
            columns={[
              { data: 'id', readOnly: true },
              { data: 'pCode', readOnly: true },
              { data: 'description', readOnly: true },
              { data: 'packType', readOnly: true },
              { data: 'numberOfScrewsToSash', type: 'numeric' },
              { data: 'numberOfScrewsToOuter', type: 'numeric' },
              { data: 'screwsPutInOnSashline', type: 'numeric' },
              { data: 'screwsPutInOnHanging', type: 'numeric' },
              { data: '', renderer: renderButton, readOnly: true }
            ]}
            hiddenColumns={{
              columns: [0],
              indicators: true
            }}
            width="100%"
            height={'auto'}
            colWidths={[0, 75, 450, 100, 110, 110, 140, 140, 50]}
            licenseKey="non-commercial-and-evaluation" // for non-commercial use only
            afterOnCellMouseDown={(e, coords, TD) =>
              handleCellClick(e, coords, TD)
            }
            afterChange={changes => onChangeCell(changes)}
          />
        </div>
      </div>
    </>
  ) : (
    <AccessDenied></AccessDenied>
  );
};

export default ScrewsMaxtrixes;
