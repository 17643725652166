import dayjs from 'dayjs';
import React from 'react';
import Barcode from 'react-barcode';
import { Image } from 'react-bootstrap';
export const FrameSashLabel = ({ itemsPrinting }) => {
  const buildBarcode = (headerId, frameId, sashNo) => {
    let header = (headerId + '').padStart(9, '0');
    let frame = (frameId + '').padStart(4, '0');
    let sash = '001' + (sashNo + '').padStart(2, '0');

    return header + frame + sash;
  };
  return (
    itemsPrinting.length > 0 &&
    itemsPrinting.map((item, id) => (
      <div
        key={id}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: 'black',
          width: '100mm'
        }}
      >
        <div>
          <Image
            src={`${process.env.REACT_APP_API_HOST}/FrameImage/${item.jobNo}/${item.winNo}`}
            width={140}
            height={140}
            style={{ objectFit: 'scale-down' }}
          />
        </div>
        <div
          style={{
            fontSize: 10,
            display: 'flex',
            flexDirection: 'column',
            width: '255px'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <div style={{ fontWeight: 600, fontSize: 14 }}> {item.jobNo}</div>
            <div>Win No. {item.winNo}</div>
          </div>

          <div>
            <div>{item.customer}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>Ref. {item.reference}</div>
            <div>{dayjs(new Date(item.requiredDate)).format('DD/MM/YYYY')}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div>LOC. {item.location}</div>
            <div>B/N. {item.batchNumber}</div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Barcode
              value={buildBarcode(item.headerId, item.frameId, item.sashNo)}
              height={18}
              width={1.5}
              fontSize={12}
            />
          </div>
        </div>
      </div>
    ))
  );
};
