import StationSpinner from 'components/station/common/Spinner';
import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPartV5ImageSize } from 'redux/slices/widgetSlice';
import { getPartItemsV2 } from 'services/partService';

export default function PartsWidgetV5(props) {
  let barcode = props.barcode;
  let groupName = props.groupName;
  let panelSetupId = props.panelSetupId;

  const [partItems, setPartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let partItemRows = [];

  const partImgSize = useSelector(store => store.widget.partV5ImageSize);
  const dispatch = useDispatch();
  let width = '';
  let height = '';

  useEffect(() => {
    if (panelSetupId) dispatch(getPartV5ImageSize(panelSetupId));
  }, [panelSetupId, barcode]);

  useEffect(() => {
    if (barcode != 0) {
      setIsLoading(true);
      getPartItemsV2(barcode, groupName, true).then(async res => {
        setIsLoading(false);

        if (partItems.length > 0 && res?.partItems.length > 0) {
          let finalArray = partItems.map(function (el) {
            return el.pCode;
          });

          let diff = res?.partItems.filter(el => {
            return !finalArray.includes(el.pCode);
          });

          if (diff.length > 0) {
            await props.updateQueue('isCodeChanged');
            await props.setIsCodeChanged(true);
            props.setDiff(
              diff.map(function (el) {
                return el.pCode;
              })
            );
          } else {
            props.setIsCodeChanged(false);
            props.setDiff([]);
          }
        }
        await setPartItems(res?.partItems);
      });
    } else {
      setPartItems([]);
    }
  }, [barcode]);

  width = partImgSize.width ? partImgSize.width + 'px' : '100px';
  height = partImgSize.height ? partImgSize.height + 'px' : '100px';

  if (partItems?.length > 0) {
    partItems.forEach(function (item, index) {
      let textDecorationCss = '';

      if (item.fontUnderline && item.fontStrikeOut) {
        textDecorationCss = 'underline line-through';
      } else {
        if (item.fontUnderline) textDecorationCss = 'underline';
        else if (item.fontStrikeOut) textDecorationCss = 'line-through';
      }

      const imgActualPath = item.s3ImagePath
        ? item.s3ImagePath.replace('s3/eva-files/', '')
        : '';

      partItemRows.push(
        <tr
          key={'row' + index}
          style={{
            color: item.fontColour,
            backgroundColor: item.bkColour,
            fontWeight: item.fontBold ? 'bold' : '',
            fontSize: item.fontSize,
            fontStyle: item.fontItalic ? 'italic' : '',
            textDecoration: textDecorationCss
          }}
        >
          <td>{item.qty}</td>
          <td>{item.pCode}</td>
          <td>{item.description}</td>
          <td>{item.partPosition}</td>
          <td>
            {imgActualPath ? (
              <img
                style={{
                  width: width,
                  height: height
                }}
                src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${process.env.REACT_APP_BUCKET_NAME}?key=${imgActualPath}`}
              ></img>
            ) : (
              'No image uploaded'
            )}
          </td>
        </tr>
      );
    });
  } else {
    partItemRows.push(
      <tr key={'row0'}>
        <td colSpan={4}>
          {props.groupName
            ? 'No records found.'
            : 'Please Select The Filter Group!'}
        </td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>P/Code</th>
            <th>Description</th>
            <th>Position</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>{partItemRows}</tbody>
      </Table>
    </>
  );
}
