import axios from 'axios';

export const getAllHingeScrewsMatrixes = async () => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/screws-matrix/all`
  );

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export const uploadFile = async files => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/screws-matrix/file`,
    method: 'POST',
    data: files
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export const deleteProfile = async id => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/screws-matrix/${id}`,
    method: 'DELETE'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export const updateProfile = async data => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/screws-matrix`,
    method: 'PUT',
    data: data
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export const getScrewsProfileByPcodes = async pCodes => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/screws-matrix/by-pcodes?pCodes=${pCodes}`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};
