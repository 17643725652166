import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

export default function SashImageHighlighted({ content }) {
  return content ? (
    <Image
      width={450}
      style={{ objectFit: 'contain', maxHeight: '450px', maxWidth: '450px' }}
      src={`data:image/jpeg;base64, ${content.fileContents}`}
      className="center-block"
      alt="frame image"
      unoptimized="true"
    />
  ) : (
    <h4>No image found</h4>
  );
}
