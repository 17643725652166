import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

export default function FrameImage({ jobNo, winNo, isBox }) {
  const [path, setPath] = useState('');
  useEffect(() => {
    let imgPath = `${process.env.REACT_APP_API_HOST}/FrameImage/${jobNo}/${winNo}`;
    if (isBox) {
      imgPath = `${process.env.REACT_APP_API_HOST}/FrameImage/${jobNo}/bay/${winNo}`;
    }
    setPath(imgPath);
  }, [jobNo, winNo, isBox]);

  return jobNo ? (
    <Image
      src={path}
      width={450}
      style={{ objectFit: 'contain', maxHeight: '450px', maxWidth: '450px' }}
      alt="frame image"
      unoptimized="true"
    />
  ) : (
    <h4>No image found</h4>
  );
}
